<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Procurar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Procurar"
            type="text"
            class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      id="table_notification_list_user"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: lastSentIcon -->
        <span v-else-if="props.column.field === 'lastSentIcon'" class="text-center-position">
            <feather-icon v-if="props.row.lastSentIcon !== ''" icon="SendIcon" size="16" />
        </span>

        <!-- Column: hasFile -->
        <span v-else-if="props.column.field === 'hasFile'" class="text-center-position">
            <feather-icon v-if="props.row.hasFile === true" icon="PaperclipIcon" size="16" />
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-link
              title="Ler notificação"
              variant="outline-primary"
              :to="{
                name: 'notification-message',
                params: { id: props.row.id, notificationUserId: props.row.notificationUserId },
              }">
              <feather-icon icon="FileTextIcon" class="mr-50" />
            </b-link>
          </span>
          <span class="space-icon">
            <b-link
                title="Excluir notificação"
                variant="outline-primary"
                @click="showModal(props.row.notificationUserId)">
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ totalCount }} Resultados </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- modal -->
    <b-modal
        id="modal-footer-sm"
        ref="modal-delete-notification"
        title="Excluir notificação"
        button-size="sm"
        ok-title="Excluir"
        cancel-title="Fechar"
        cancel-variant="outline-secondary"
    >
      <b-card-text>
        Deseja excluir essa notificação?
      </b-card-text>
      <template v-slot:modal-footer="{ hide }">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            @click="deleteNotification()">Excluir</b-button>
        <b-button @click="hide('hide')">Fechar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  // BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BModal,
  VBModal,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
// import ModalUser from './ModalUser.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import Moment from 'moment/moment'
import store from '@/store/index'

export default {
  components: {
    // BCardCode,
    VueGoodTable,
    BAvatar,
    // BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    // BCol,
    // ModalUser,
    BLink,
    BModal,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      notification_id: '',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Data de Criação',
          field: 'creationTime',
        },
        {
          label: 'Assunto',
          field: 'notificationName',
        },
        {
          label: 'Enviada',
          field: 'lastSentIcon',
        },
        {
          label: 'Anexos',
          field: 'hasFile',
        },
        {
          label: 'Data Útimo Envio',
          field: 'lastSent',
        },
        {
          label: 'Ação',
          field: 'action',
        },
      ],
      row: [],
      totalCount: 0,
      currentPage: 1,
      rows: [],
      searchTerm: '',
      status: [
        {
          1: 'Analise',
          2: 'Ativo',
          3: 'Rejeitado',
          4: 'Bloqueado',
          5: 'Suspenso',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Analise: 'light-primary',
        Ativo: 'light-success',
        Rejeitado: 'light-danger',
        Bloqueado: 'light-warning',
        Suspenso: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    async findData() {
      await this.$http.get(
        '/api/services/app/Notification/GetUserNotifications',
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
    },
    currentPage: {
      handler(val) {
        this.findData()
      },
      immediate: true,
    }
  },
  mounted() {
    this.findData()
  },
  methods: {
    async findData() {
      let filters = `SkipCount=${(10 * this.currentPage) - 10}`
      const response = await this.$http.get(
        '/api/services/app/Notification/GetUserNotifications?'+filters,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
      this.rows = response.data.result.items.map(res => ({
        id: res.notification.id,
        notificationUserId: res.id,
        creationTime: this.frontEndDateFormat(res.notification.creationTime),
        notificationName: res.notification.notificationName,
        data: res.notification.data,
        severity: res.notification.severity,
        hasFile: res.notification.hasFile,
        lastSent: res.notification.lastSent ? this.frontEndDateFormat(res.notification.lastSent) : '',
        lastSentIcon: res.notification.lastSent ? this.frontEndDateFormat(res.lastSent) : '',
      }))
      this.totalCount = response.data.result.totalCount
    },
    async deleteNotification() {
      await this.$http.delete(
        `/api/services/app/Notification/DeleteUserNotification?Id=${this.notification_id}`,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
      this.$refs['modal-delete-notification'].hide()
      this.findData()
    },
    frontEndDateFormat(v) {
      return Moment(v, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },

    // modal
    showModal(id) {
      this.notification_id = id
      this.$refs['modal-delete-notification'].show()
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.page-item.active .page-link {
    border-radius: 5rem;
    background-color: var(--color) !important;
    border-color: var(--color) !important;
}
.text-center-position {
  margin-left: 45%;
}

#table_notification_list_user tr {
  border: 0;
  display: block;
  margin: 15px 3px;
  border: 1px #fff solid;
  border-radius: 10px;
}

#table_notification_list_user td {
  padding: 5px;
}
#table_notification_list_user tbody tr {
  background: #fff;
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

#table_notification_list_user  .vgt-table.bordered td,  #table_notification_list_user .vgt-table.bordered th {
  border: #fff !important;
}

//#table_notification_list_user table.vgt-table td {
//  padding: 0.75em 0.75em 0.75em 0.75em !important;
//}

#table_notification_list_user .vgt-left-align {
  text-align: left !important;
}

#table_notification_list_user table.vgt-table td {
  vertical-align: baseline !important;
  color: #6e6b7b;
}
#table_notification_list_user table.vgt-table thead {
  display: none;
}
#table_notification_list_user table.vgt-table tbody {
  font-size: 13px;
}
#table_notification_list_user table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
}
#table_notification_list_user .vgt-table th.line-numbers, #table_notification_list_user .vgt-table th.vgt-checkbox-col {
  padding: 0 2.75em 0 0.75em;
}
#table_notification_list_user table tbody td:first-child { max-width: 120px; min-width: 120px; }
#table_notification_list_user table tbody td:nth-child(2) { max-width: 220px; min-width: 220px; padding: 22px 0 22px 0; }
#table_notification_list_user table tbody td:nth-child(3) { max-width: 230px; min-width: 230px; }
#table_notification_list_user  table tbody td:nth-child(4) { max-width: 120px; min-width: 120px; }
#table_notification_list_user  table tbody td:nth-child(5) { max-width: 120px; min-width: 120px; }
#table_notification_list_user table tbody td:nth-child(6) { max-width: 80px; min-width: 80px; float: right; margin-top: -55px; }
#table_notification_list_user a { color: #828282; }
#table_notification_list_user a:hover { color: var(--color); }
.space-icon { margin-left: 10px; }

#table_notification_list_user td[colspan="7"] {
  text-align: center;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
</style>
